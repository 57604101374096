import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import respond from "../../styles/abstracts/mediaqueries"

import Card from "../Card"
import CardImage from "../CardImage"

const StyledCard12 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }

  .card-image {
    width: 40%;
    margin: 4rem auto;

    ${respond(
      "phone-port",
      css`
        width: 70%;
      `
    )}
  }
`
const Card12 = () => {
  return (
    <StyledCard12 className="container card-container">
      <Card>
        <h3>PERCHÉ GIOVANNI SETTI È LA FIGURA GIUSTA PER TE</h3>
        <CardImage className="card-image">
          <StaticImage
            src="../../images/giovanni.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Giovanni Setti"
            quality={100}
          />
        </CardImage>
        <p>
          Nel panorama nazionale la figura del Consulente Finanziario
          indipendente è purtroppo ancora{" "}
          <strong>una vera e propria rarità.</strong>
        </p>
        <p>
          <strong>Iscritto all’albo dei professionisti</strong>, Giovanni non è
          il classico “consulente fanfarone”, prova ne sono i suoi{" "}
          <strong>numerosi studi e certificazioni.</strong>
        </p>
        <p>
          A una giovane età, ha saputo conseguire a Charlotte, in North
          Carolina, negli Stati Uniti, un’importante{" "}
          <strong>laurea in “Business Administration”.</strong> Dopodiché, ha
          perfezionato i suoi studi con un <strong>Master a Ginevra</strong> in
          “Investment Management”.
        </p>
        <p>
          Nonostante i già rilevanti conseguimenti in ambito educativo, non ha
          voluto fermarsi, raggiungendo anche <strong>un secondo Master</strong>{" "}
          in “Consulenza e Pianificazione Finanziaria Indipendente”. Si è anche{" "}
          <strong>
            specializzato in finanza sostenibile e strategie di investimento ESG
          </strong>{" "}
          (“Environmental, social and corporate governance” - un’analisi dei
          fattori d’impatto ambientale e sociale nei meccanismi decisionali e
          pratici di un’azienda).
        </p>
        <p>
          In ambito pratico, Giovanni fin da giovanissimo ha saputo brillare{" "}
          <strong>organizzando un’importante “exit”</strong> della
          multinazionale “Tessilgraf” con la vendita della stessa a{" "}
          <strong>un importante fondo di investimento americano</strong>, con un
          ritorno sull’investimento decisamente importante e ben superiore alle
          aspettative.
        </p>
        <p>
          Dopo la sua lunga formazione, costellata da numerosi successi, decide
          di portare la sapienza accumulata in patria, dove, grazie al suo
          approccio giovane e dinamico,{" "}
          <strong>
            è addirittura arrivato a dare consulenza ai consulenti finanziari
            stessi
          </strong>{" "}
          per portargli le tecniche di investimento più sicure e moderne,
          abbandonando un modello rigido e arretrato.
        </p>
        <p>
          Giovanni ha anche scritto{" "}
          <strong>il libro “Come Investire Partendo da Zero”</strong>, un vero
          successo nel settore e punto di riferimento per il mercato della
          finanza personale.
        </p>
        <p>
          Infine, si è voluto dedicare alla diffusione anche gratuita del suo
          sapere, <strong>creando un canale YouTube</strong> sul soggetto e
          accumulando migliaia di follower sui social media, molti dei quali
          hanno saputo cambiare la loro vita in ambito finanziario grazie ai
          consigli ivi riportati.
        </p>
      </Card>
    </StyledCard12>
  )
}

export default Card12
