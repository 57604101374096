import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Card from "../Card"
import CardImage from "../CardImage"

const StyledCard3 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card3 = () => {
  return (
    <StyledCard3 className="container card-container">
      <Card>
        <h3>
          NEGLI INVESTIMENTI, OGNI SECONDO PASSATO, &Egrave; UN SECONDO SPRECATO
        </h3>

        <CardImage>
          <StaticImage
            src="../../images/sales/card-3-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Persona che sorregge una lampadina con grafici futuristici"
            quality={90}
          />
        </CardImage>
        <p>
          Ebbene sì, prima si inizia a investire, prima si inizia a guadagnare
          e, con un utile medio del 7/8% all’anno, si possono raggiungere,
          grazie all’interesse composto,{" "}
          <strong>cifre davvero importanti.</strong>
        </p>
        <p>
          A tutti gli effetti, l’investimento rappresenta al giorno d’oggi il
          modo più sicuro per{" "}
          <strong>
            far passare la propria famiglia da uno stato di povertà a uno stato
            di ricchezza
          </strong>
          , nell’arco di una singola generazione, anche senza passare
          necessariamente per uno sviluppo imprenditoriale importante.
        </p>
      </Card>
    </StyledCard3>
  )
}

export default Card3
