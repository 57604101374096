import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Card from "../Card"
import CardImage from "../CardImage"

const StyledCard4 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card4 = () => {
  return (
    <StyledCard4 className="container card-container">
      <Card>
        <h3>LA PENSIONE, QUESTO MIRAGGIO INAVVICINABILE</h3>

        <CardImage>
          <StaticImage
            src="../../images/sales/card-4-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Persona che scrive su un tablet con grafici in sovrimpressione"
            quality={90}
          />
        </CardImage>
        <p>
          Con il rapido succedersi delle crisi mondiali e a causa
          dell'invecchiamento della popolazione, la stabilità del pensionamento
          è un <strong>miraggio sempre più lontano</strong>.
        </p>
        <p>
          Il modo più sicuro per poter godere di una buona situazione economica
          e di una rendita costante durante il periodo della vecchiaia, è,
          ormai, <strong>l’investimento privato.</strong>
        </p>
        <p>
          Grazie alla consulenza di Giovanni Setti, potrai aumentare in modo
          incredibile la tua pensione (in certi casi potrà addirittura
          raddoppiare).{" "}
          <strong>Perché accontentarsi di quanto ti passa l’INPS</strong> quando
          puoi, fin d’ora, lavorare per il tuo futuro?
        </p>
        <h3>&Egrave; TUTTO UNA VALLE DI LACRIME?</h3>
        <p>
          “Ok, grazie, abbiamo visto tutte le cattive notizie, ma, quindi, come
          rimediamo?”
        </p>
        <p>
          Hai ragione, finora abbiamo parlato del problema, ma sei veramente
          fortunato, perché, finalmente,{" "}
          <strong>è arrivata una vera e propria figura ufficiale</strong> a
          portare ordine nel caos del mercato finanziario.
        </p>
      </Card>
    </StyledCard4>
  )
}

export default Card4
