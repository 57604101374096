import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Card from "../Card"
import CardImage from "../CardImage"

const StyledCard2 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card2 = () => {
  return (
    <StyledCard2 className="container card-container">
      <Card>
        <h3>Gli errori fatali degli investitori</h3>
        <blockquote>
          Da sempre gli investitori cercano di raggiungere prosperità e successo
          in ambito finanziario, ma purtroppo, troppo spesso, sono soggetti a
          terribili errori:
        </blockquote>
        <CardImage>
          <StaticImage
            src="../../images/sales/card-2-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Due persone eleganti che mostrano un tablet con dei grafici"
            quality={90}
          />
        </CardImage>
        <ul>
          <li>
            <strong>Si affidano a persone completamente incapaci</strong> che
            vogliono soltanto vendere il loro (spesso pessimo) prodotto
            finanziario, che si rivela una vera e propria “ciofeca”;
          </li>
          <li>
            Decidono di fare tutto da soli per risparmiare poche centinaia di
            euro e, alla prima difficoltà, presi dall’agitazione, fanno gli
            errori più basilari arrivando a{" "}
            <strong>perdere molti soldi;</strong>
          </li>
          <li>
            Anziché investire in modo serio si mettono a speculare in modo
            forsennato, arrivando a{" "}
            <strong>perdere molto più di quanto investono</strong> (lo sapevi
            che 9 trader su 20 perdono quando investono i loro soldi o quelli
            dei loro sfortunati clienti?);
          </li>
          <li>
            Si mettono, appunto, a “giocare” in borsa, come fosse una sorta di
            roulette, o si mettono a investire sull’ultima criptovaluta uscita,
            la moda del momento, arrivando a perdere anche il 90%, o peggio, di
            quanto investito;
          </li>
          <li>
            O, forse la condizione peggiore tra tutte quelle citate, decide che
            semplicemente non ha tempo per investire e si limita a risparmiare,
            vedendosi negati gli{" "}
            <strong>
              incredibili benefici oggi disponibili grazie all'interesse
              composto.
            </strong>
          </li>
        </ul>
        <p>
          Non preoccuparti se ti sei ritrovato in qualcuno di questi esempi,{" "}
          <strong>ti capisco benissimo.</strong> Anch’io all’inizio ho commesso
          i miei errori e di certo sei in ottima compagnia, ma non tutto è
          perduto, puoi ancora rimediare e{" "}
          <strong>
            assicurare a te e alla tua famiglia il futuro che veramente
            meritate.
          </strong>
        </p>
        <p>
          Ma cosa succede invece se decidi di continuare così, continuando a
          perdere soldi investendo in modo scriteriato o, ancora peggio, se non
          fai nulla, lasciando sul tavolo tutti i veri benefici che ti può dare
          un{" "}
          <strong>
            investimento fatto con criterio e valutato professionalmente?
          </strong>{" "}
          Guarda la direzione in cui sta andando il mondo, sei sicuro che{" "}
          <strong>la tua pensione sarà assicurata</strong> tra venti o
          trent’anni? Non è più il momento di affidarsi solo alle soluzioni
          tradizionali, è ora che TU decida di{" "}
          <strong>prendere in mano il tuo futuro</strong>.
        </p>
      </Card>
    </StyledCard2>
  )
}

export default Card2
