import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import respond from "../../styles/abstracts/mediaqueries"

import Card from "../Card"
import CardImage from "../CardImage"
import HeroButton from "../HeroButton"

const StyledRequirementsCard = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
  .card-image {
    width: 80%;
    margin: 0 auto 6rem auto;

    ${respond(
      "phone-port",
      css`
        width: 100%;
        margin: 0 auto 3.5rem auto;
      `
    )}
  }
`
const RequirementsCard = () => {
  return (
    <StyledRequirementsCard className="container card-container">
      <Card>
        <h3>REQUISITI</h3>
        <CardImage className="card-image">
          <StaticImage
            src="../../images/sales/requirements-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Parola requisiti e due persone stilizzate che si stringono la mano."
          />
        </CardImage>
        <p>
          L’unico requisito per accedere alla consulenza è la possibilità di
          investire un minimo di{" "}
          <strong>€300 al mese o di €30.000 una tantum</strong>.
        </p>
        <p>
          Per quanto per noi{" "}
          <strong>
            l’onorario non cambi a seconda dell’entità dell’investimento
          </strong>
          , il nostro principale interesse sono i risultati e il nostro nome è
          tutto per noi.
        </p>
        <p>
          Per questo, non ci sentiamo di garantire il massimo dei risultati con
          investimenti inferiori a questa cifra, pertanto, apriamo la consulenza
          gratuita soltanto a{" "}
          <strong>
            chi può investire come minimo le cifre sopra riportate
          </strong>
          .
        </p>
        <p>
          Non sai se possiedi i requisiti necessari?{" "}
          <strong>
            Manda comunque la candidatura tramite il modulo sottostante
          </strong>{" "}
          e vedremo la cosa insieme!
        </p>
        <div className="mt-4"></div>
        <HeroButton text="CANDIDATI SUBITO!" />
      </Card>
    </StyledRequirementsCard>
  )
}

export default RequirementsCard
