import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import respond from "../../styles/abstracts/mediaqueries"

const StyledTestimonialsContainer = styled.div`
  display: grid;
  width: 80%;
  margin: 0 auto;
  grid-template-columns: 1fr;
  gap: 3rem;
  align-items: flex-start;
  justify-items: center;
  justify-content: center;

  ${respond(
    "phone-port",
    css`
      display: block;
      width: 100%;
    `
  )}

  img {
    max-width: 100%;
    height: max-content;
    border-radius: 30px;

    ${respond(
      "phone-port",
      css`
        border-radius: 15px;
      `
    )}
  }

  .image {
    height: max-content;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
`

const TestimonialsContainer = () => {
  const {
    allFile: { images },
  } = useStaticQuery(query)

  return (
    <StyledTestimonialsContainer>
      {images.map((image, i) => {
        const parsedImage = getImage(image.childImageSharp)
        if (!parsedImage) return ""
        return (
          <GatsbyImage
            key={i}
            image={parsedImage}
            alt="Testimonianza a Giovanni Setti"
            className="image"
          />
        )
      })}
    </StyledTestimonialsContainer>
  )
}

const query = graphql`
  query TestimonialImages {
    allFile {
      images: nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          original {
            height
          }
        }
      }
    }
  }
`

export default TestimonialsContainer
