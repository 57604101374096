import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import respond from "../../styles/abstracts/mediaqueries"

import Card from "../Card"
import CardImage from "../CardImage"
import HeroButton from "../HeroButton"

const StyledOfferCard = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
  .card-image {
    width: 80%;
    margin: 0 auto 6rem auto;

    ${respond(
      "phone-port",
      css`
        width: 100%;
        margin: 0 auto 3.5rem auto;
      `
    )}
  }
`
const OfferCard = () => {
  return (
    <StyledOfferCard className="container card-container">
      <Card>
        <h3>IN COSA CONSISTE LA NOSTRA OFFERTA</h3>
        <CardImage className="card-image">
          <StaticImage
            src="../../images/sales/offer-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Giovanni Setti | Consulenza Gratuita | Offerta Speciale"
          />
        </CardImage>
        <p>
          Se oggi deciderai di contattarci tramite il modulo sottostante, potrai
          beneficiare,{" "}
          <strong>
            per un tempo limitato e solo fino all’esaurimento dei posti
            disponibili
          </strong>
          , di una consulenza direttamente con Giovanni Setti, della durata
          necessaria per esporre e analizzare insieme a lui la tua situazione e
          discutere la migliore strategia per i tuoi investimenti.
        </p>
        <p>
          Giovanni ti ascolterà, analizzerà con te la tua situazione e ti
          sottoporrà un'offerta per elaborare, esattamente in base alle tue
          esigenze, <strong>il miglior piano di investimenti</strong>, tagliato
          su misura per te.
        </p>
        <div className="mt-4"></div>
        <HeroButton text="CANDIDATI SUBITO"></HeroButton>
      </Card>
    </StyledOfferCard>
  )
}

export default OfferCard
