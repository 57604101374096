import React from "react"
import styled, { css } from "styled-components"
import respond from "../styles/abstracts/mediaqueries"

const StyledCardExample = styled.div`
  background-color: rgba(237, 241, 255, 0.9);
  border-radius: 35px;
  text-align: center;
  padding: 4rem;
  margin-bottom: 4rem;

  ${respond(
    "phone-port",
    css`
      padding: 3rem 2.5rem;
      border-radius: 20px;
    `
  )}

  h5 {
    color: var(--color-secondary);
  }
  p {
    font-weight: 600;
  }
`

const CardExample = props => {
  return <StyledCardExample>{props.children}</StyledCardExample>
}

export default CardExample
