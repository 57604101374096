import React from "react"
import styled from "styled-components"

import Card from "../Card"

const StyledCard11 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card11 = () => {
  return (
    <StyledCard11 className="container card-container">
      <Card>
        <h3>BEH, POTREI COMUNQUE FARE TUTTO DA SOLO!</h3>
        <p>
          Come già spiegato, mi sento di sconsigliare questa via. A meno che non
          ti piaccia perdere soldi.
        </p>
        <p>
          Allo stesso modo di quando si ha un dente cariato e si va da un
          dentista, quando si parla di investimenti è meglio allo stesso modo{" "}
          <strong>rivolgersi a un esperto ufficiale e certificato.</strong>
        </p>
        <p>
          In effetti, chi si accinge al “trading”,{" "}
          <strong>perde soldi nove volte su dieci.</strong>
        </p>
        <p>
          Eppure, il mercato finanziario, negli anni, ha uno storico decisamente
          robusto e, complessivamente, gli indici principali{" "}
          <strong>sono sempre saliti con una media del 6/7%!</strong> Chi fa da
          solo rischia di perdere tutti i soldi, chi si affida a un
          professionista potrà sfruttare gli enormi profitti generati dai
          mercati finanziari.
        </p>
        <p>
          Perché rischiare e fare tutto da soli? Rivolgiti a un professionista e
          ottieni un piano su misura!
        </p>
      </Card>
    </StyledCard11>
  )
}

export default Card11
