import React, { useContext } from "react"
import { navigate } from "gatsby"
import AppContext from "../context/AppContext"
import StyledForm from "./StyledForm"
import SubmitLayer from "./SubmitLayer"
import axios from "axios"

import { createLinkWithParams, encodeForm } from "../utils/utils"
import { LocationContext } from "../context/LocationContext"

const Form = () => {
  const {
    setAlertState,
    formData,
    setFormData,
    isFormSubmitting,
    setIsFormSubmitting,
  } = useContext(AppContext)

  const { params } = useContext(LocationContext)
  const link = createLinkWithParams("/candidatura", params)

  // useEffect(() => {
  //   console.log(formData)
  // }, [formData])

  function handleFormChange(e) {
    if (e.target.type === "checkbox")
      return setFormData({ ...formData, [e.target.name]: e.target.checked })
    return setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setIsFormSubmitting(true)
    try {
      const axiosRequest = axios.create()

      await axiosRequest.post(
        process.env.GATSBY_ZAPIER_ENDPOINT,
        encodeForm(formData),
        { headers: { Accept: "application/json" } }
      )
      setIsFormSubmitting(false)
      navigate(link, { state: { formData } })
    } catch (err) {
      setAlertState({
        successful: false,
        message:
          "È avvenuto un errore nell'invio del modulo. Per favore scrivici a info@giovannisetticonsulting.com",
        shown: true,
      })
      setTimeout(() => {
        setAlertState({ successful: false, message: "", shown: false })
      }, 10000)
    } finally {
      setIsFormSubmitting(false)
    }
  }

  return (
    <StyledForm className="container" id="contatti">
      <h3 className="title">
        Prenota la consulenza con Giovanni Setti oggi stesso!
      </h3>
      <p className="subtitle">Compila il modulo per candidarti.</p>
      <p className="message"></p>
      <form
        action="/contacts"
        className="form"
        method="POST"
        onSubmit={handleSubmit}
      >
        <div className="input-container">
          <label htmlFor="first_name" className="label">
            Nome
          </label>
          <input
            type="text"
            className="text-input"
            id="first_name"
            name="first_name"
            required
            value={formData.first_name}
            onChange={handleFormChange}
          />
        </div>
        <div className="input-container">
          <label htmlFor="last_name" className="label">
            Cognome
          </label>
          <input
            type="text"
            className="text-input"
            id="last_name"
            name="last_name"
            required
            value={formData.last_name}
            onChange={handleFormChange}
          />
        </div>
        <div className="input-container">
          <label htmlFor="phone_number" className="label">
            Telefono
          </label>
          <input
            type="tel"
            className="text-input"
            id="phone_number"
            name="phone_number"
            required
            value={formData.phone_number}
            onChange={handleFormChange}
          />
        </div>
        <div className="input-container">
          <label htmlFor="email" className="label">
            Email
          </label>
          <input
            type="email"
            className="text-input"
            id="email"
            name="email"
            required
            value={formData.email}
            onChange={handleFormChange}
          />
        </div>
        <div className="checkboxes-container">
          <div className="input-container full-width checkbox-container">
            <input
              type="checkbox"
              className="checkbox"
              name="privacy_accepted"
              id="privacy_accepted"
              required
              checked={formData.privacy_accepted}
              onChange={handleFormChange}
            />
            <label htmlFor="privacy_accepted" className="label">
              Accetto la politica sulla privacy e i termini e le condizioni del
              sito.
            </label>
          </div>
          <div className="input-container full-width checkbox-container">
            <input
              type="checkbox"
              className="checkbox"
              name="newsletter_accepted"
              id="newsletter_accepted"
              required
              checked={formData.newsletter_accepted}
              onChange={handleFormChange}
            />
            <label htmlFor="newsletter_accepted" className="label">
              Sì! Voglio ricevere informazioni promozionali e aggiornamenti
              sulle vostre attività.
            </label>
          </div>
          <div className="submit-container full-width">
            <button className="submit" type="submit">
              <span className="loader d-none"></span>
              <span className="submit--text">Invia il Modulo</span>
            </button>
          </div>
        </div>
      </form>
      {isFormSubmitting && <SubmitLayer isSubmitting={isFormSubmitting} />}
    </StyledForm>
  )
}

export default Form
