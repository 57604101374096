import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Card from "../Card"
import CardImage from "../CardImage"

const StyledCard6 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card6 = () => {
  return (
    <StyledCard6 className="container card-container">
      <Card>
        <h3>PERCHÉ I NUOVI INVESTITORI FALLISCONO E COME RIMEDIARE</h3>

        <CardImage>
          <StaticImage
            src="../../images/sales/card-6-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Persona che tocca un grafico avanguardistico"
            quality={100}
          />
        </CardImage>
        <p>
          Spesso i nuovi investitori falliscono perché{" "}
          <strong>
            non trattano l’investimento come una vera e propria professione.
          </strong>
        </p>
        <p>
          Proprio come un medico, un avvocato o un ragioniere devono{" "}
          <strong>
            studiare anni per capire come svolgere il loro lavoro,
          </strong>{" "}
          allo stesso modo è necessario avere una formazione finanziaria
          importante per capire come investire al meglio i propri averi per
          avere un <strong>ritorno importante sull’investimento</strong> e
          minimizzare i rischi.
        </p>
        <p>
          Il vero problema di chi inizia a investire è che, spesso sopraffatto
          dalla routine quotidiana, non ha tempo di formarsi in modo completo
          sull’economia e investe dunque “alla cieca”,{" "}
          <strong>
            rischiando nove volte su dieci di fare errori da novizio
          </strong>
          , sprecando così l’incredibile potenziale che gli investimenti mettono
          a disposizione.
        </p>
        <p>
          Per questo, la cosa migliore da fare è{" "}
          <strong>rivolgersi a un professionista</strong>
          ufficiale e abilitato che saprà guidarti verso le scelte migliori per
          il tuo caso specifico.
        </p>
      </Card>
    </StyledCard6>
  )
}

export default Card6
