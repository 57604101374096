import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import respond from "../../styles/abstracts/mediaqueries"

import Card from "../Card"
import CardImage from "../CardImage"

const StyledGuaranteeCard = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
  .card-image {
    width: 80%;
    margin: 0 auto 6rem auto;

    ${respond(
      "phone-port",
      css`
        width: 100%;
        margin: 0 auto 3.5rem auto;
      `
    )}
  }
`
const GuaranteeCard = () => {
  return (
    <StyledGuaranteeCard className="container card-container">
      <Card>
        <h3>UNA GARANZIA SOLIDA, UN INVESTIMENTO SICURO</h3>
        <CardImage className="card-image">
          <StaticImage
            src="../../images/sales/guarantee-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Parola requisiti e due persone stilizzate che si stringono la mano."
          />
        </CardImage>
        <p>
          La nostra garanzia è assoluta. Se per qualsiasi motivo non ti troverai
          bene con Giovanni o non vorrai proseguire nell’investimento una volta
          ascoltata la sua proposta, <strong>NULLA SARÀ DOVUTO</strong>.
        </p>
        <p>
          L’unico rischio connesso a questa offerta, è l’investimento di trenta
          minuti del tuo tempo.
        </p>
        <p>
          Questa offerta non presenta dunque{" "}
          <strong>nessun rischio per te</strong>. L’unico vero rischio è il non
          avvalersene per sfiducia, scetticismo o per il semplice pensiero che è
          “troppo bello per essere vero”.{" "}
          <strong>Non lasciarti sfuggire questa occasione!</strong>
        </p>
      </Card>
    </StyledGuaranteeCard>
  )
}

export default GuaranteeCard
