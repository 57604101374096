import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Card from "../Card"
import CardImage from "../CardImage"

const StyledCard10 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card10 = () => {
  return (
    <StyledCard10 className="container card-container">
      <Card>
        <h3>
          L’INVESTIMENTO IN AMBITO BANCARIO E ASSICURATIVO, PIENA TRASPARENZA O
          COMPLETA OPACITÀ?
        </h3>
        <CardImage>
          <StaticImage
            src="../../images/sales/card-10-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Persona che tocca un ologramma di un grafico"
          />
        </CardImage>
        <p>
          Purtroppo, negli investimenti tradizionali, non abbiamo nessun
          controllo e <strong>non sappiamo dove vanno i nostri soldi</strong>.
        </p>
        <p>
          Tu lavori duramente per poter guadagnare abbastanza da poter investire
          e loro, spesso,{" "}
          <strong>lucrano tranquillamente alle tue spalle</strong>. Gli dai i
          tuoi soldi, ottenuti col sudore della tua fronte, e non sai nemmeno
          cosa ti stanno facendo comprare.
        </p>
        <p>
          <strong>Prendi il pieno controllo!</strong> Un Consulente Finanziario
          Indipendente ti indicherà solamente la via più profittevole e avrai
          sempre il completo controllo sui tuoi investimenti!
        </p>
        <p>
          Appurato quindi che il modo migliore al giorno d’oggi è, dunque,
          sicuramente affidarsi a un Consulente Finanziario Indipendente,{" "}
          <strong>perché scegliere proprio Giovanni?</strong>
        </p>
      </Card>
    </StyledCard10>
  )
}

export default Card10
