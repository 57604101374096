import React from "react"
import styled from "styled-components"

import Card from "../Card"

const StyledCard13 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card13 = () => {
  return (
    <StyledCard13 className="container card-container">
      <Card>
        <h3>
          "ODDIO, MA IL PIANO DOPO LA CONSULENZA COSTERÀ UN OCCHIO DELLA TESTA,
          COSA PRENOTO A FARE?!"
        </h3>
        <p>
          Esatto, questo è il bello. Normalmente Giovanni Setti è{" "}
          <strong>
            il Consulente Finanziario Indipendente più pagato in Italia.
          </strong>
        </p>
        <p>
          Ma, solo per un breve periodo, ha deciso di aprire le sue porte a{" "}
          <strong>una serie di consulenze gratuite</strong> e offrire
          successivamente il suo piano di investimento personale, creato su
          misura per te, a{" "}
          <strong>
            una tariffa decisamente agevolata e accessibile a chiunque
          </strong>
          , senza costi mensili nascosti.
        </p>
        <p>
          <strong>
            "Ascolta, Giovanni, sembra tutto bellissimo, ma io non ho proprio il
            tempo di stare dietro a queste cose, sono un libero
            professionista/imprenditore e lavoro 12 ore al giorno quando va
            bene!!!"
          </strong>
        </p>
        <p>
          Lo so, ti capisco bene, questa è un’obiezione che sento spesso ma non
          preoccuparti, <strong>ho la soluzione perfetta per te!</strong>
        </p>
        <p>
          Grazie al sistema di investimento che ti proporrò, particolarmente per
          i piani di accumulo (i più profittevoli), farò in modo che il tutto
          sia <strong>completamente automatizzato</strong>.
        </p>
        <p>
          Al giorno che stabiliremo, verrà semplicemente inviato un ordine
          automatico al broker dove seminerai il tuo piccolo (o, perché no,
          enorme!) fagiolo che ti darà grandi risultati nel tempo.
        </p>
        <p>
          Non dovrai fare niente, se non avere la pazienza di{" "}
          <strong>aspettare e perseverare nel tempo</strong>, mentre il mercato
          finanziario, sorretto dalle aziende più importanti, lavora per te
          anche mentre dormi!
        </p>
      </Card>
    </StyledCard13>
  )
}

export default Card13
