import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import respond from "../../styles/abstracts/mediaqueries"

import Card from "../Card"
import CardImage from "../CardImage"
import HeroButton from "../HeroButton"

const StyledCtaCard = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
  .card-image {
    width: 80%;
    margin: 0 auto 6rem auto;

    ${respond(
      "phone-port",
      css`
        width: 100%;
        margin: 0 auto 3.5rem auto;
      `
    )}
  }
`
const CtaCard = () => {
  return (
    <StyledCtaCard className="container card-container">
      <Card>
        <h3>È GIUNTA L’ORA DI DECIDERE</h3>
        <CardImage className="card-image">
          <StaticImage
            src="../../images/sales/cta-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Tante porte bianche e solo una gialla con scritto È ORA DI DECIDERE."
          />
        </CardImage>
        <p>
          Come per ogni questione importante nella vita, anche oggi{" "}
          <strong>
            è arrivata l’ora di decidere per il tuo destino e quello della tua
            famiglia
          </strong>
          .
        </p>
        <p>
          Puoi dedicarci, e dedicare a te stesso, un pochino del tuo tempo,
          approfittando della consulenza gratuita di Giovanni Setti e ricevere
          un piano di investimenti su misura che{" "}
          <strong>cambierà il tuo futuro e quello dei tuoi figli</strong>.
        </p>
        <p>
          Oppure, puoi continuare a fare quello che stai facendo, magari{" "}
          <strong>sprecando centinaia di migliaia di euro</strong> investendo
          con figure opache del mondo finanziario, senza sapere per cosa stai
          pagando, e{" "}
          <strong>
            ottenendo solo una minima parte dei risultati che potresti ottenere
          </strong>
          .
        </p>
        <p>
          O, peggio ancora, puoi <strong>decidere di non fare niente</strong>,
          continuando a non investire, e non facendo mai fare alla tua vita{" "}
          <strong>il salto di qualità che veramente meriti</strong>.
        </p>
        <p>
          Prendi una decisione diversa e{" "}
          <strong>ottieni un risultato diverso</strong>.
        </p>
        <p>
          <strong>Compila OGGI STESSO il modulo sottostante</strong>. Inserisci
          il tuo nome, il tuo cognome, la tua email e il tuo numero di telefono.
        </p>
        <p>
          Arriverai alla seconda parte del modulo dove verificheremo i requisiti
          e, se qualificherai, potrai accedere a una schermata dove{" "}
          <strong>prenoterai direttamente la tua consulenza gratuita</strong>{" "}
          durante l'orario a te più comodo, in base ai posti liberi in quel
          momento.
        </p>
        <p>
          Non lasciarti sfuggire questa occasione. Ti aspettiamo e{" "}
          <strong>non vediamo l’ora di conoscerti!</strong>
        </p>
        <div className="mt-4"></div>
        <HeroButton text="RICHIEDI ORA!" />
      </Card>
    </StyledCtaCard>
  )
}

export default CtaCard
