import React from "react"
import styled from "styled-components"

import Layout from "../layout/Layout"
import Seo from "../components/Seo"
import Hero from "../components/sales/Hero"

import Card1 from "../components/sales/Card1"
import Card2 from "../components/sales/Card2"
import Card3 from "../components/sales/Card3"
import Card4 from "../components/sales/Card4"
import Card5 from "../components/sales/Card5"
import Card6 from "../components/sales/Card6"
import Card7 from "../components/sales/Card7"
import Card8 from "../components/sales/Card8"
import Card9 from "../components/sales/Card9"
import Card10 from "../components/sales/Card10"
import Card11 from "../components/sales/Card11"
import Card12 from "../components/sales/Card12"
import TestimonialsCard from "../components/sales/TestimonialsCard"
import OfferCard from "../components/sales/OfferCard"
import PriceCard from "../components/sales/PriceCard"
import Card13 from "../components/sales/Card13"
import RequirementsCard from "../components/sales/RequirementsCard"
import GuaranteeCard from "../components/sales/GuaranteeCard"
import CtaCard from "../components/sales/CtaCard"
import FaqQuestions from "../components/sales/FaqQuestions"
import PSCard from "../components/sales/PSCard"

import Form from "../components/Form"

const StyledConsulenza = styled.main``

const ConsulenzaPage = () => (
  <Layout salesLetter>
    <Seo title="Giovanni Setti | Consulenza Gratuita" />
    <StyledConsulenza>
      <Hero />
      <Card1 />
      <Card2 />
      <Card3 />
      <Card4 />
      <Card5 />
      <Card6 />
      <Card7 />
      <Card8 />
      <Card9 />
      <Card10 />
      <Card11 />
      <Card12 />
      <TestimonialsCard />
      <OfferCard />
      <PriceCard />
      <Card13 />
      <RequirementsCard />
      <GuaranteeCard />
      <CtaCard />
      <Form />
      <FaqQuestions />
      <PSCard />
    </StyledConsulenza>
  </Layout>
)

export default ConsulenzaPage
