import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Card from "../Card"
import CardImage from "../CardImage"

const StyledCard5 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card5 = () => {
  return (
    <StyledCard5 className="container card-container">
      <Card>
        <h3 className="underlined">
          UNA VERA SOLUZIONE, FINALMENTE DA UNA FIGURA UFFICIALE
        </h3>

        <CardImage>
          <StaticImage
            src="../../images/sales/card-5-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Persona che tiene dei grafici in ologramma tra le mani"
            quality={90}
          />
        </CardImage>
        <p>
          Purtroppo fino al 2019 non esisteva una figura{" "}
          <strong>veramente indipendente</strong> nell’ambito degli
          investimenti.
        </p>
        <p>
          L’unica soluzione era dunque rivolgersi a delle persone che si
          spacciavano come consulenti, ma che, in realtà, erano solo venditori
          dei propri prodotti. Non a caso, fino a poco tempo fa, venivano
          chiamati <strong>“promotori finanziari”</strong>, proprio perché il
          loro lavoro è promuovere i prodotti della banca o compagnia di
          assicurazioni che li ha assunti, non fare <strong>consulenza</strong>.
        </p>
        <p>
          Il problema è che queste figure{" "}
          <strong>
            guadagnano solamente dalla vendita del proprio prodotto
          </strong>{" "}
          e, non avendo alcun rapporto di indipendenza nei confronti del
          cliente, non erano in grado necessariamente di{" "}
          <strong>guidarlo verso quanto era meglio per lui.</strong>
        </p>
        <p>
          Oggi, invece, esiste una nuova posizione professionale, ancora poco
          conosciuta, ma completamente ufficiale, che si chiama “Consulente
          Finanziario Indipendente” e rappresenta{" "}
          <strong>
            una vera e propria novità nel quadro normativo italiano.
          </strong>{" "}
          Questo consulente ufficiale è abilitato a seguire personalmente le
          finanze dei privati e delle aziende,{" "}
          <strong>curando gli investimenti dei propri clienti</strong>, senza
          essere condizionato in nessun modo dai prodotti finanziari promossi.{" "}
        </p>
      </Card>
    </StyledCard5>
  )
}

export default Card5
