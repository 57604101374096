import React from "react"
import styled from "styled-components"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import MuiTypography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Card from "../Card"

import questions from "../../content/questions.json"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightBold,
    color: "var(--color-secondary)",
  },
  text: {
    fontSize: theme.typography.pxToRem(28),
    textAlign: "left",
  },
  icon: {
    fontSize: "30px",
  },
}))

const Accordion = withStyles({
  root: {
    border: "1px solid #d5d5d5",
    padding: "2rem",

    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: { margin: 0 },
  content: { margin: "0 !important", textAlign: "left" },
})(MuiAccordionSummary)

const Typography = withStyles({
  root: { width: "100% !important" },
})(MuiTypography)

const StyledQuestionsContainer = styled.div`
  h3 {
    margin-bottom: 4rem;
  }

  p {
    width: 100%;
  }
`

function FaqQuestions() {
  const classes = useStyles()

  return (
    <StyledQuestionsContainer className="container card-container">
      <Card>
        <h3>DOMANDE E RISPOSTE</h3>
        {questions.map(({ question, answer }, i) => (
          <Accordion key={i}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon className={classes.icon} fontSize="inherit" />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>{question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={classes.text}
                dangerouslySetInnerHTML={{ __html: answer }}
              ></Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Card>
    </StyledQuestionsContainer>
  )
}

export default FaqQuestions
