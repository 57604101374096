import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import Card from "../Card"
import CardImage from "../CardImage"

const StyledCard1 = styled.div`
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card1 = () => {
  return (
    <StyledCard1 className="container card-container">
      <Card>
        <h3>Attenzione, investitori o aspiranti tali</h3>
        <blockquote>
          “Tutti pensavano che investire in borsa fosse un processo
          complicatissimo e molto rischioso, ma alla fine si sono dovuti
          ricredere…”
        </blockquote>
        <CardImage>
          <StaticImage
            src="../../images/sales/card-1-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Persone con un computer e una calcolatrice"
            quality={90}
          />
        </CardImage>
        <p className="color-secondary intro">Rivelati in questa pagina…</p>

        <ul>
          <li>
            <strong>
              <i>Il trucco sporco del sistema:</i>
            </strong>{" "}
            in che modo gli investimenti tradizionali proposti da consulenti
            bancari e assicurativi sono deleteri per i tuoi soldi;
          </li>

          <li>
            <strong>
              <i>La figura mancante:</i>
            </strong>{" "}
            scopri la figura del Consulente Finanziario Indipendente e perché
            rappresenta per te un vero e proprio “nuovo inizio” nel campo degli
            investimenti e una scorciatoia vincente per mettere al sicuro i tuoi
            soldi e la tua pensione;
          </li>
          <li>
            <strong>
              <i>L’unico sistema funzionale</i>
            </strong>{" "}
            per rendere un investimento nel mercato finanziario un vero
            investimento sul tuo futuro e non una pericolosa speculazione;
          </li>
          <li>
            <strong>
              <i>
                <span className="underline">UN’OCCASIONE UNICA</span>
              </i>{" "}
            </strong>
            <strong>
              - Il tuo accesso esclusivo a una consulenza gratuita che cambierà
              seriamente il tuo futuro dal punto di vista economico.
            </strong>
          </li>
        </ul>
        <p>
          <strong>Se non hai mai investito in borsa</strong> per paura di
          perdere un sacco di soldi, se non ti fidi dei tuoi consulenti
          finanziari perché sai che
          <strong> vogliono solo venderti i loro prodotti finanziari</strong>,
          se vedi la speculazione come un rischio (e forse fai bene), se sai che
          <strong>
            {" "}
            non investendo stai lasciando un sacco di soldi sul tavolo
          </strong>
          , se sai che la pensione tradizionale sarà più o meno un’utopia e se
          non ti fidi a investire da solo perché sai che non avrai mai veramente
          il tempo per gestire la cosa, la pagina che stai per leggere è{" "}
          <strong>esattamente</strong> quello che stavi aspettando,
          probabilmente ormai da lungo tempo.
        </p>
      </Card>
    </StyledCard1>
  )
}

export default Card1
