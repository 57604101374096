import React from "react"
import styled, { css } from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import respond from "../../styles/abstracts/mediaqueries"

import HeroButton from "../HeroButton"

const StyledHero = styled.div`
  color: var(--white);
  position: relative;

  ${respond(
    "big-desktop",
    css`
      margin-bottom: 7rem;
    `
  )}

  h1,
  h2 {
    font-size: 4rem;
    text-transform: uppercase;
    text-align: center;
    transform: translateY(-5rem);
    font-weight: 600;

    ${respond(
      "ipad-pro-11-port",
      css`
        font-size: 3rem;
      `
    )}
    ${respond(
      "iphone-12-pro-land",
      css`
        font-size: 3rem;
      `
    )}
    ${respond(
      "iphone-12-land",
      css`
        font-size: 2.5rem;
      `
    )}
    ${respond(
      "phone-port",
      css`
        font-size: 2rem;
      `
    )}
    ${respond(
      "small-phone",
      css`
        font-size: 1.8rem;
      `
    )}
    ${respond(
      "iphone-5",
      css`
        font-size: 1.5rem;
      `
    )}
  }

  h1 {
    color: var(--white);
  }
  h2 {
    color: var(--color-tertiary);
  }

  .container {
    position: relative;
    height: 60vh;
    width: 100%;

    ${respond(
      "notebook",
      css`
        height: 50rem;
      `
    )}
    ${respond(
      "laptop-s",
      css`
        height: 45rem;
      `
    )}
     ${respond(
      "phone-port",
      css`
        height: 60vh;
      `
    )}
    ${respond(
      "iphone-5",
      css`
        height: 70vh;
      `
    )}
  }

  .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${respond(
      "ipad-pro-12-port",
      css`
        width: 80%;
      `
    )}
  }

  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    clip-path: var(--arrow-shape);
    z-index: -1;

    ${respond(
      "macbook-air",
      css`
        clip-path: polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%);
      `
    )}
  }

  .decorative-hero {
    position: absolute;
    bottom: -15px;
    left: 50%;
    background-color: white;
    width: 81%;
    height: 100%;
    transform: translateX(-50%);
    z-index: -2;
    clip-path: var(--arrow-shape);
    border-radius: 30px;
    box-shadow: 13.8179px 40.4666px 98.6991px rgba(49, 89, 211, 0.12);

    ${respond(
      "macbook-air",
      css`
        clip-path: polygon(0 0, 100% 0, 100% 75%, 50% 100%, 0 75%);
      `
    )}
  }
`

const Hero = () => {
  return (
    <StyledHero>
      <div className="container">
        <div className="text-container">
          <h1>GIOVANNI SETTI</h1>
          <h2>Consulenza Finanziaria Indipendente</h2>
          <HeroButton text="APPROFITTA DELL'OFFERTA" />
        </div>
        <StaticImage
          src="../../images/sales/hero-image.png"
          alt="Businessman che tiene un tablet"
          className="hero-image"
          placeholder="tracedSVG"
          layout="constrained"
        />
      </div>
      <div className="decorative-hero container"></div>
    </StyledHero>
  )
}

export default Hero
