import React from "react"
import styled from "styled-components"

import Card from "../Card"
import HeroButton from "../HeroButton"

const StyledPSCard = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
  .card-image {
    width: 80%;
    margin: 0 auto 6rem auto;
  }
`
const PSCard = () => {
  return (
    <StyledPSCard className="container card-container">
      <Card>
        <p>
          PS: <strong>È arrivato il momento dell’azione</strong>. Il mondo
          bancario e assicurativo si è assicurato di monopolizzare questo
          mercato per troppi anni, tenendo per sé praticamente{" "}
          <strong>
            tutto il profitto che i mercati finanziari sono stati in grado di
            generare
          </strong>
          . Oggi hai l’occasione di cambiare questa situazione. Prendi in mano
          il coraggio per cambiare la tua vita e scegliere un nuovo futuro,
          facendo{" "}
          <strong>
            un importante passo avanti verso la realizzazione dei tuoi sogni
          </strong>
          , prima che sia troppo tardi.{" "}
          <strong>CLICCA QUI PER FARLO ORA!</strong>
        </p>
        <div className="mt-4"></div>
        <HeroButton text="RICHIEDI ORA!" />
      </Card>
    </StyledPSCard>
  )
}

export default PSCard
