import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Card from "../Card"
import CardImage from "../CardImage"
import CardExample from "../CardExample"

const StyledCard8 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card8 = () => {
  return (
    <StyledCard8 className="container card-container">
      <Card>
        <h3>
          QUANTO SI RISPARMIA UTILIZZANDO UN CONSULENTE FINANZIARIO
          INDIPENDENTE?
        </h3>
        <CardImage>
          <StaticImage
            src="../../images/sales/card-8-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Persona che indicano qualcosa in un edificio"
            quality={100}
            formats={["WEBP"]}
          />
        </CardImage>
        <p>
          Uno dei punti che spesso vengono sottovalutati è l’enorme impatto dei{" "}
          <strong>costi dei prodotti di investimento.</strong>
        </p>
        <p>
          Continuando a investire utilizzando i loro prodotti, fondamentalmente{" "}
          <strong>
            andiamo a regalare, giorno dopo giorno, ingenti somme di denaro
          </strong>{" "}
          a queste figure.
        </p>
        <p>
          Investendo per conto tuo, invece, pagherai solo{" "}
          <strong>le piccolissime tariffe imposte dai vari broker</strong> che
          il Consulente ti aiuterà a scegliere, sulla base delle tue specifiche
          esigenze.
        </p>
        <p>
          Ma, l’aspetto davvero sensazionale, è il totale risparmiato in venti o
          trent’anni di investimenti con un Consulente Finanziario Indipendente,
          il quale spesso{" "}
          <strong>si fa pagare solo per la sua consulenza iniziale.</strong>
        </p>
        <p>
          Ovviamente dipende dal patrimonio investito, ma si parla spesso di{" "}
          <strong>centinaia di migliaia di euro</strong> che possono essere
          facilmente risparmiati investendo con un Consulente Indipendente
          piuttosto che con un consulente bancario o assicurativo.
        </p>
        <p>
          A tutti gli effetti, un Consulente Finanziario Indipendente NON PUÒ
          prendere commissioni sui prodotti che consiglia. Questo fattore
          garantisce, appunto, la sua totale indipendenza,{" "}
          <strong>mettendoti completamente al sicuro dagli squali</strong> che
          spesso girano nell’ambiente bancario e assicurativo.
        </p>
        <h5>Ecco alcuni esempi di risparmio concreti:</h5>
        <CardExample>
          <h5>Esempio 1</h5>
          <ul>
            <li>Investimento mensile: 300€</li>
            <li>Rendimento medio annuale: 7%</li>
            <li>Orizzonte temporale d’investimento: 30 anni</li>
            <li>Costo del portafoglio creato da Giovanni Setti: 0,2% annuo</li>
            <li>Costo dei prodotti di investimento bancari: 2,2% annuo</li>
          </ul>
          <p>Patrimonio raggiunto con Giovanni Setti: 339.249€</p>
          <p>Patrimonio raggiunto con gli investimenti bancari: 233.180€</p>
          <p>Soldi persi investendo con la banca: 106.069€</p>
        </CardExample>
        <CardExample>
          <h5>Esempio 2</h5>
          <ul>
            <li>Investimento mensile: 500€</li>
            <li>Rendimento medio annuale: 8%</li>
            <li>Orizzonte temporale d’investimento: 40 anni</li>
            <li>Costo del portafoglio creato da Giovanni Setti: 0,2% annuo</li>
            <li>Costo dei prodotti di investimento bancari: 2,2% annuo</li>
          </ul>
          <p>Patrimonio raggiunto con Giovanni Setti: 1.530.538€</p>
          <p>Patrimonio raggiunto con gli investimenti bancari: 884.551€</p>
          <p>Soldi persi investendo con la banca: 645.987€</p>
        </CardExample>
        <p>
          Come puoi notare, non stiamo parlando di noccioline, ma di centinaia e
          centinaia di migliaia di euro risparmiati.
        </p>
      </Card>
    </StyledCard8>
  )
}

export default Card8
