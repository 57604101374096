import React from "react"
import styled, { css } from "styled-components"
import { Link } from "react-scroll"
import respond from "../styles/abstracts/mediaqueries"

const StyledHeroButton = styled.div`
  a {
    color: var(--white);
    display: block;
    margin: 0 auto;
    background-color: blue;
    width: max-content;
    padding: 2rem 4rem;
    border-radius: 50px;
    background: linear-gradient(193.04deg, #5ad7ff -25.23%, #0b6cf2 88.84%);
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: var(--white);

    ${respond(
      "small-phone",
      css`
        font-size: 1.5rem;
        padding: 1.5rem 2rem;
      `
    )}
    ${respond(
      "iphone-5",
      css`
        font-size: 1.3rem;
        padding: 1.5rem 1rem;
      `
    )}  

  &:hover {
      transform: translateY(-3px) scale(1.05);
    }
  }
`

const HeroButton = ({ text }) => {
  function handleClick(e) {
    e.preventDefault()
  }

  return (
    <StyledHeroButton>
      <Link
        // activeClass="active"
        to="contatti"
        smooth={true}
        offset={-70}
        duration={1200}
        onClick={handleClick}
      >
        {text}
      </Link>
    </StyledHeroButton>
  )
}

export default HeroButton
