import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import respond from "../../styles/abstracts/mediaqueries"

import Card from "../Card"
import CardImage from "../CardImage"
import HeroButton from "../HeroButton"

const StyledPriceCard = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
  .card-image {
    width: 80%;
    margin: 0 auto 6rem auto;

    ${respond(
      "phone-port",
      css`
        width: 100%;
        margin: 0 auto 3.5rem auto;
      `
    )}
  }
`
const PriceCard = () => {
  return (
    <StyledPriceCard className="container card-container">
      <Card>
        <h3>MMH.. MA QUANTO COSTA TUTTO QUESTO?!</h3>
        <CardImage className="card-image">
          <StaticImage
            src="../../images/sales/price-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Quanto costa?"
          />
        </CardImage>
        <p>
          E qui sta il bello della cosa,{" "}
          <strong>
            la consulenza è completamente gratuita e senza nessun tipo di
            impegno.
          </strong>
        </p>
        <p>Ti chiederai “beh, cosa ci guadagna così”?</p>
        <p>
          Semplice, siamo così fiduciosi nella nostra capacità di creare un
          piano giusto per te, e così sicuri che apprezzerai la nostra offerta,
          che siamo disposti a “rischiare” il nostro tempo, dandoti una vera e
          propria consulenza gratuita. Inoltre, finora nessuno ha rinunciato al
          suo piano di investimento, siamo dunque seriamente convinti della
          bontà della nostra offerta.
        </p>
        <p>
          Da notare che{" "}
          <strong>non si tratta di una “chiamata di vendita”</strong>. Giovanni
          è un Consulente Indipendente e in questa chiamata farà semplicemente
          il suo lavoro.
        </p>
        <p>
          Alla fine dell’incontro, ti verrà proposto{" "}
          <strong>un piano personalizzato di investimento</strong> e, a quel
          punto, potrai fare un pagamento una tantum e seguire il tuo piano per
          tutta la vita. In caso la tua situazione fosse più complessa, Giovanni
          potrebbe consigliarti una consulenza continuativa, ovvero ti offrirà{" "}
          <strong>il suo completo supporto</strong> per la durata di un anno. La
          decisione tra la consulenza una tantum e quella continuativa ti verrà
          consigliata da Giovanni in sede di consulenza.
        </p>
        <p>
          Se, invece, non sarai convinto e non vorrai proseguire, saremo{" "}
          <strong>amici come prima</strong>.
        </p>

        <p>
          <strong>Concedici mezz’ora del tuo tempo</strong> e avrai la
          possibilità di accedere a un piano di investimento cucito su misura in
          base ai tuoi obiettivi.
        </p>
        <div className="mt-4"></div>
        <HeroButton text="APPROFITTA DELL'OFFERTA!"></HeroButton>
      </Card>
    </StyledPriceCard>
  )
}

export default PriceCard
