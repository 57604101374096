import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Card from "../Card"
import CardImage from "../CardImage"

const StyledCard7 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card7 = () => {
  return (
    <StyledCard7 className="container card-container">
      <Card>
        <h3>
          PERCHÉ CHI TI INSEGNA COME INVESTIRE NON È MAI IN GRADO DI CREARE
          VERAMENTE RICCHEZZA?
        </h3>

        <CardImage>
          <StaticImage
            src="../../images/sales/card-7-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Persone eleganti che discutono di un grafico"
            quality={100}
          />
        </CardImage>
        <p>Il problema di queste persone è, solitamente, duplice.</p>
        <p>
          Da una parte troviamo{" "}
          <strong>
            lo scarso background teorico nel campo economico e finanziario.
          </strong>
        </p>
        <p>
          Per programmare efficacemente gli investimenti, così da dare un buon
          prodotto ai propri clienti e{" "}
          <strong>garantire la prosperità nel tempo</strong>, è necessario avere
          una solida conoscenza dei meccanismi coinvolti.
        </p>
        <p>
          Inoltre, spesso queste figure, per ottimizzare i loro guadagni,{" "}
          <strong>insegnano in linea generale</strong>, senza entrare nello
          specifico nelle situazioni personali e, dunque, non riescono a
          ottimizzare i loro piani finanziari e crearli su misura come solo una
          vera e propria consulenza professionale di un Consulente Finanziario
          Indipendente può fare.
        </p>
        <p>
          L’altro problema, non di certo minore, è il loro coinvolgimento
          economico con la vendita del prodotto finanziario che offrono.
        </p>
        <p>
          Percependo commissioni dalla vendita di quanto propongono, non
          suggerirebbero mai alternative{" "}
          <strong>potenzialmente molto più profittevoli</strong> ai propri
          clienti, essendo limitati a un ventaglio di opportunità alquanto
          ridotto. Il lavoro di questi “consulenti” è portare a casa le loro
          commissioni, non far fare soldi a te.
        </p>
      </Card>
    </StyledCard7>
  )
}

export default Card7
