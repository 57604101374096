import React from "react"
import styled, { css } from "styled-components"
import respond from "../../styles/abstracts/mediaqueries"

import Card from "../Card"
import TestimonialsContainer from "./TestimonialsContainer"

const StyledTestimonialsCard = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }

  p {
    margin-bottom: 4rem;

    ${respond(
      "phone-port",
      css`
        margin-bottom: 4rem !important;
      `
    )}
  }

  .intro {
    text-align: center;
    margin: 4rem 0;
  }

  .card-image {
    width: 40%;
    margin: 4rem auto;
  }

  .actual-card {
    ${respond(
      "phone-port",
      css`
        padding-top: 3rem;
      `
    )}
  }
`
const TestimonialsCard = () => {
  return (
    <StyledTestimonialsCard className="container card-container">
      <Card>
        <p className="color-secondary">
          Ma non devi credere a Giovanni solamente in base al suo background,
          ecco alcuni casi studio che ti dimostrano esattamente cosa è stato in
          grado di produrre per i suoi clienti:
        </p>
        <TestimonialsContainer />
      </Card>
    </StyledTestimonialsCard>
  )
}

export default TestimonialsCard
