import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Card from "../Card"
import CardImage from "../CardImage"

const StyledCard9 = styled.div`
  h3 {
    text-transform: uppercase;
  }
  h5 {
    font-style: italic;
    font-size: var(--basic-font-size);
  }
  .intro {
    text-align: center;
    margin: 4rem 0;
  }
`
const Card9 = () => {
  return (
    <StyledCard9 className="container card-container">
      <Card>
        <h3>
          MA INVESTENDO SONO VINCOLATO E NON POSSO RIPRENDERE IL MIO DENARO?
        </h3>
        <CardImage>
          <StaticImage
            src="../../images/sales/card-9-image.png"
            placeholder="blurred"
            layout="fullWidth"
            alt="Persona in giacca blu che si stringono la mano"
          />
        </CardImage>
        <p>
          Sono ormai leggendarie{" "}
          <strong>
            le difficoltà che i risparmiatori incontrano cercando di ritirare i
            propri soldi
          </strong>{" "}
          dai fondi tradizionali. D’altronde la banca guadagna commissioni sui
          tuoi soldi ogni giorno. Il suo obiettivo sarà trattenere i tuoi soldi
          il più a lungo possibile.
        </p>
        <p>
          Lunghe file in banca, scartoffie da compilare e{" "}
          <strong>molti altri problemi</strong> sono all’ordine del giorno.
        </p>
        <p>
          Investendo per conto tuo, invece,{" "}
          <strong>i soldi saranno estremamente liquidi</strong>, e potrai
          rientrare in possesso dei tuoi soldi non appena lo desidererai (e ci
          mancherebbe altro, i soldi sono tuoi).
        </p>
        <p>
          Devi sapere che, per legge, un Consulente Finanziario Indipendente, al
          contrario delle banche,{" "}
          <strong>
            non può entrare in possesso dei soldi dei suoi clienti
          </strong>
          , ma può solo consigliarti il miglior piano di investimento sulla base
          delle tue esigenze.{" "}
          <strong>I soldi rimarranno sempre e solo nelle tue mani.</strong>
        </p>
      </Card>
    </StyledCard9>
  )
}

export default Card9
